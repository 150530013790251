import React, { useCallback, useEffect, useState } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import RrspYouContributeComp
    from "../../../../components/ContentRow/Step3/Rrsp/RrspYouContributeComp/RrspYouContributeComp";
import RrspSpouseContributeComp
    from "../../../../components/ContentRow/Step3/Rrsp/RrspSpouseContributeComp/RrspSpouseContributeComp";
import { thirdStepCompleted } from "../../../../store/actions/savingsAndInvestments";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const RrspContribute = ( props ) => {
    const titles = {
        layoutTitle: 'RRSP',
        sectionTitle: 'How much do you plan to contribute monthly?',
    }

    const title = "Monthly contribution in RRSP for retirement";

    const [next, setNext] = useState(retirementCalcLinks.properties);

    const nextLink = useCallback(() => {
        if(props.you.workplaceSavings.exist || props.spouse.workplaceSavings.exist) {
            return retirementCalcLinks.workplaceSavingsHave;
        }else if(props.you.lockedIn.exist || props.spouse.lockedIn.exist) {
            return retirementCalcLinks.lockedInHave;
        }else if(props.you.tfsa.exist || props.spouse.tfsa.exist){
            return retirementCalcLinks.tfsaHave;
        }else if(props.you.nonRegistered.exist || props.spouse.nonRegistered.exist){
            return retirementCalcLinks.nonRegisteredHave;
        }else {
            return retirementCalcLinks.properties;
        }
    }, [props]);

    useEffect(() => {
        props.thirdStepCompleted(false);
        setNext(nextLink())
    }, [props, nextLink]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(3/16)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent=''
                sectionTitle={titles.sectionTitle}
                modal={false}
            >

                {
                    props.you.rrsp.exist &&
                    <InnerLayoutStepContent type={'you'}>
                        <RrspYouContributeComp/>
                    </InnerLayoutStepContent>
                }

                {
                    props.isSpouse && props.spouse.rrsp.exist &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <RrspSpouseContributeComp/>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        you: state.savingsAndInvestments.you,
        spouse: state.savingsAndInvestments.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RrspContribute);