import React from 'react';
import MonthlyContribution from "../../MonthlyContribution/MonthlyContribution";
import { setRrspContributeSpouse } from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const RrspSpouseContributeComp = ( props ) => {
    const onChange = val => {
        props.setRrspContributeSpouse(val)
    }

    return (
        < MonthlyContribution
            total={props.rrspContribute}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        rrspContribute: state.savingsAndInvestments.spouse.rrsp.contributeMonthly
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setRrspContributeSpouse : val => dispatch(setRrspContributeSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RrspSpouseContributeComp);