import React, { useEffect, useState } from 'react';
import classes from "../Total/Total.module.scss";
import Error from "../../../Error/Error";
import InputMask from "react-input-mask";

const MonthlyContribution = ( props ) => {
    const [value, setValue] = useState(props.total || 0);
    const [error, setError] = useState(false);

    function validation(val) {
        if(val < 1){
            setError(true)
        }else {
            setError(false)
        }
    }

    const onChange = e => {
        const value = +e.target.value.replace(/\$|\s/g, '');
        setValue(value);
        validation(value);
        props.onChange(value)
    };

    useEffect(()=> {
        validation(props.total)
    }, [props]);

    return (
        <div className={classes.TotalWrap}>
            {
                error &&
                <Error
                    message={'Value can not be $0'}
                />
            }
            <div className={classes.InputWrap}>
                <InputMask
                    mask="$999999"
                    value={`$${value}`}
                    onChange={onChange}
                    maskPlaceholder={null}
                />
            </div>
            <p>Monthly contribution</p>
        </div>
    )
};

export default MonthlyContribution;