import React from 'react';
import classes from "../InnerLayout/InnerLayout.module.scss";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import MoreInfo from "../../components/MoreInfo/MoreInfo";
import './InnerLayoutStep.scss'

const InnerLayoutStep = ( props ) => {
    const modal = props.modal === false ? false : true

    return (
        <div className={classes.InnerLayout}>
            <h2>{props.sectionTitle}</h2>
            <div className='AboutYou'>
                {props.children}
            </div>
            <div className={classes.MoreInfo}>
                {
                    modal === true &&
                    <React.Fragment>
                        <Popup modal trigger={<div className={classes.openPopup}>
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                style={{fontSize: 60,cursor: 'pointer', color:'#3967b1'}}
                            />
                        </div>}>
                        {close => <MoreInfo type={props.modalContent} close={close}/>}
                        </Popup>
                        <span className={classes.MoreInfoTitle}>More info</span>
                    </React.Fragment>
                }
            </div>
        </div>
    )
};

export default InnerLayoutStep;