import React from 'react';
import classes from "./InnerLayoutStepContent.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

const InnerLayoutStepContent = ( props ) => {
    let icon;
    let iconTitle;

    if (props.type === 'you' ){
        icon = <FontAwesomeIcon
                        icon={faUserCircle}
                        style={{fontSize: 60, color: 'rgb(57, 103, 177)'}}
                    />
        iconTitle = 'You'
    }else if (props.type === 'family' ){
        icon = <React.Fragment>
                <FontAwesomeIcon
                    icon={faUserCircle}
                    style={{fontSize: 60, color: 'rgb(57, 103, 177)'}}
                />
                <FontAwesomeIcon
                    icon={faUserCircle}
                    style={{fontSize: 60, color: 'rgb(57, 103, 177, .6)'}}
                />
            </React.Fragment>
        iconTitle = 'Family'
    }else if(props.type === 'spouse') {
        icon = <FontAwesomeIcon
                    icon={faUserCircle}
                    style={{fontSize: 60, color: 'rgb(57, 103, 177, .6)'}}
                />
        iconTitle = 'Spouse'
    }else {
        icon =''
        iconTitle = ''
    }

    return (
        <div className={classes.YouInfoWrap}>
            <div className={classes.You}>
                <div>
                    {icon}
                </div>
               {iconTitle}
            </div>
            <div className={classes.MainContent}>
                {props.children}
            </div>
        </div>
    )
};

export default InnerLayoutStepContent;